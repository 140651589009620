<template>
    <!-- 顶部 -->
    <header class="containerHeader">
        <div class="leftRightHeader"></div>
        <div class="centerHeader">
            <div class="flex-between">
                <div class="flex_items">
    				<el-image :src="imageSrcTwo" class="logo_style" @click="goIndex"></el-image>
                </div>
    			<div>
    				<el-menu :default-active="$route.path" router mode="horizontal" active-text-color="#D6B98E">
    					<el-menu-item index="/" class="custom-menu-item">首页</el-menu-item>
						<el-menu-item index="/business" class="custom-menu-item">业务领域</el-menu-item>
    					<el-menu-item index="/news" class="custom-menu-item">资讯中心</el-menu-item>
						<el-menu-item index="/about" class="custom-menu-item">关于我们</el-menu-item>
						<el-menu-item index="/contact" class="custom-menu-item">联系我们</el-menu-item>
    				</el-menu>
    			</div>
            </div>
        </div>		
        <div class="leftRightHeader"></div>
    </header>
</template>

<script>
export default {
    data() {
        return {
			imageSrcTwo: require('../static/images/logo.jpg'),
        }
    },
	created() {
		
	},
    methods: {
		goIndex() {
			if (this.$route.path !== '/') {
			    this.$router.push('/');
			}
		},
	},
}
</script>

<style>
.containerHeader{
    display: flex;
    flex-direction: row;
    flex: 1;
    border-bottom: 1px solid #e6e6e6;
	width: 100%;
	height: 60px;
	line-height: 60px;
	background-color: white;
	z-index: 1000;
}
.centerHeader{
    flex: 0 0 1200px;
    height: 60px;
}
.leftRightHeader{
    flex: 1;
    height: 60px;
}
.logo_style {
	width: 115px;
	height: 30px;
	cursor: pointer;
}
</style>
<style scoped>
.el-menu--horizontal>.el-menu-item.is-active, .el-menu--horizontal>.el-menu-item {
	border-bottom: none;
}
</style>