<template>
    <div class="footer-content flex_two_center" style="flex-direction: column;">
        <div class="vue-flex">
			<div style="margin-right: 50px;">
				<img :src="require('../static/images/footer/logo.png')" style="width: 89px;height: 89px;"/>
			</div>
			<div>
				<img :src="require('../static/images/footer/code.jpg')" style="width: 72px;height: 72px;" />
				<div>微信公众号</div>
			</div>
		</div>
		<div style="background-color: #28456c;height: 1px;width: 1200px;margin: 40px;"></div>
        <div>
			Copyright  © 2023 广东网宇科技股份有限公司 版权所有 All Rights Reserved.  
			<span style="cursor: pointer;" @click="ICPBtn">ICP备：粤ICP备14014412号</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			
        }
    },
    methods: {
		ICPBtn() {
			window.open('https://beian.miit.gov.cn/', '_blank');
		},
	},
}
</script>

<style>
	.footer-content {
        color: #527CBC;
		height: 280px;
		background-color: #1D2F4A;
    }
</style>