<template>
	<div>
		<!-- 圣杯布局 -->
		<Header style="position: fixed;"></Header>
		<div style="height: 60px;"></div>
		<div class="banner" ></div>
		<div class="container">
			<div class="leftRight"></div>
			<!-- 内容 -->
			<section class="center">
				<div style="position: relative;">
					<el-image :src="require('../static/images/business/Group-58.jpg')"></el-image>
					<div class="img_div" style="right: 40px;top: 125px;">
						公司“资本支持+产业赋能”的投资模式多次被成功验证，为具备潜力的初创企业和团队提供资金支持和产业资源导入，通过严格的风险管理和规范化运营，实现被投企业快速发展和估值提升,主要方向为数字信息应用平台及软硬件、人工智能、网络安全、运维安全、数字影视及传媒和新能源等。
					</div>
				</div>
				<div style="margin: 100px 0;position: relative;">
					<el-image :src="require('../static/images/business/Group-63.jpg')"></el-image>
					<div class="img_div" style="left: 40px;">
						网宇科创在东莞和厦门两地运营定制化的商业加速器项目，为具有创新潜力的初创企业提供孵化服务，包括提供办公场地和物业支持、配备相应的软硬件服务、政策补贴、业务培训和专家指导等，帮助初创企业加速发展并在市场中获得竞争优势。
					</div>
				</div>
				<div style="position: relative;">
					<el-image :src="require('../static/images/business/Group-60.jpg')"></el-image>
					<div class="img_div" style="right: 40px;">
						经过近十年的发展，网宇科创与业内创业导师和行业专家深入交流、合作，共同创造出先进的研究成果并积累了丰富的创业实践经验，能够为被投企业提供宝贵的创业指导、建议和战略规划。
					</div>
				</div>
				<div  style="margin: 100px 0;position: relative;">
					<el-image :src="require('../static/images/business/Group-61.jpg')"></el-image>
					<div class="img_div" style="left: 40px;">
						公司发展过程中，长期与其他各个投资机构、产业基金、券商、银行、律所、会计师事务所、咨询公司和猎头公司等深入合作，推介上述三方机构为被投企业提供增值服务，在企业战略规划、市场营销、整合融资、人才招聘、上市辅导等方面提供配套的支持，使初创企业能够专心攻克技术、优化产品，无后顾之忧。
					</div>
				</div>
				<div  style="position: relative;">
					<el-image :src="require('../static/images/business/Group-62.jpg')"></el-image>
					<div class="img_div" style="right: 40px;">
						网宇科创会推介内部已被投资孵化的企业之间进行上下游资源对接、交流技术创新、共享研究成果和讨论行业发展与机会等，被投企业根据自身发展各取所需，在网宇科创的引导和内部孵化企业的产业赋能之下，逐步探索出稳健可持续的发展模式。
					</div>
				</div>
			</section>
			<div class="leftRight"></div>
		</div>
		
		<Footer style="margin-top: 100px;"></Footer>
	</div>
</template>

<script>
	import Header from "@/components/header";
	import Footer from "@/components/footer";
	export default {
		data() {
			return {
				
			}
		},
		components: {
			Header,
			Footer,
		},
		mounted() {
		    
		},
		methods: {},
	}
</script>

<style scoped>
	.container {
		margin-top: 80px;
		display: flex;
		flex-direction: row;
		flex: 1;
	}
	.center {
		flex: 0 0 1100px;
		font-size: 16px;
	}
	.leftRight {
		flex: 1;
	}
	.footer {
		text-align: center;
	}
	.banner {
		height: 55vh;
		display: flex;
		align-items: center;
		justify-content: center;
		background: url("../static/images/business/003.jpg") no-repeat center center;
		background-size: cover; 
	}
	.img_div {
		width: 480px;
		position: absolute;
		top: 130px;
		line-height: 26px
	}
</style>
